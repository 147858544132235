import WayPenguin from "../images/reference/penguin_on_way.svg"
import Confused from "../images/reference/penguin_confused.svg"
import Confused2 from "../images/reference/penguin_confused2.svg"
import Files from "../images/reference/penguin_files.svg"
import Sitting from "../images/reference/sitting-puzzle-penguin.svg"
import Bubbles from "../images/reference/penguin_bubbles.svg"
import Screen from "../images/reference/penguin_screen.svg"
import Screen2 from "../images/reference/penguin_screen2.svg"

export const MUPO_TIMELINE = [
  {
    year: "DEFINICE CÍLOVÉ SKUPINY",
    header: "a&nbsp;přiřazení hlavních komunikovaných témat",
    image: Confused2,
  },
  {
    year: "SESTAVENÍ CESTY ZÁKAZNÍKA",
    header: "a&nbsp;jeho nákupního chování",
    image: WayPenguin,
  },
  {
    year: "SBĚR MATERIÁLŮ",
    header: "k&nbsp;novému webu",
    image: Files,
  },
  {
    year: "SESTAVENÍ PODKLADŮ </br> PRO UŽIVATELSKÝ VÝZKUM",
    header:
      "cílové skupiny -&nbsp;zákazníci /&nbsp;obchodní partneři, zaměstnanci",
    image: Sitting,
  },
  {
    year: "UŽIVATELSKÝ VÝZKUM",
    header: "a&nbsp;následná úprava struktury a&nbsp;obsahu strategie",
    image: Confused,
  },
  {
    year: "VYTVOŘENÍ WIREFRAMU",
    header: "jednotlivých stránek na webu",
    image: Bubbles,
  },
  {
    year: "VYTVOŘENÍ VIZUÁLNÍ PODOBY",
    header: "na základě brand manuálu",
    image: Screen,
  },
  {
    year: "VYTVOŘENÍ WEBOVÉ ŠABLONY",
    header: "a&nbsp;nasazení do redakčního systému DRUPAL",
    image: Screen2,
  },
]
