import React from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import {
  ContainerBackground,
  ContainerBackgroundStyle,
  ContainerCustom,
  Content,
  H2,
  Infobox,
  Medailon,
  PaddingWrapper,
  Timeline,
} from "@igloonet-web/shared-ui"
import ReferenceHeader from "../../../components/reference-detail/reference-header"
import ReferenceImageText from "../../../components/reference-detail/reference-image-text"
import NegativeMarginWrapper from "@igloonet-web/shared-ui/components/negative-margin-wrapper"
import Monitor from "../../../images/reference/mupo/monitor.png"
import Figma from "../../../images/reference/mupo/figma.png"
import FigmaMobile from "../../../images/reference/mupo/figma-mobil.png"
import Logo from "../../../images/reference/mupo/logo.png"
import OgImage from "../../../images/reference/mupo/og-image.png"
import Wireframe from "../../../images/reference/mupo/wf1.png"
import Wireframe2 from "../../../images/reference/mupo/wf2.png"
import Polasek from "../../../images/reference/mupo/mpolasek.jpg"
import { Link } from "gatsby"
import { Container } from "reactstrap"
import Sobol from "../../../images/team/mini/sobol.jpg"
import { MUPO_TIMELINE } from "../../../model/mupo-reference-timeline"

const Mupo: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>
          Uživatelský výzkum a nový web pro stavební firmu MÜPO | igloonet.cz
        </title>
        <meta
          name="description"
          content="„Na igloonet se bez váhání obrátím vždy, když potřebuji pomoct s webem, hostingem nebo reklamou Jedeme v tom spolu už více než 15 let a nechystáme se to měnit.“ Martin Polášek, jednatel"
        />
        <meta
          property="og:title"
          content="Uživatelský výzkum a nový web pro stavební firmu MÜPO | igloonet.cz"
        />
        <meta property="og:image" content={OgImage} />
      </Helmet>
      <ReferenceHeader
        companyName="MÜPO"
        heading="Uživatelský výzkum a nový web pro stavební firmu MÜPO"
        date="duben 2021 – současnost"
      />
      <ReferenceImageText
        right
        img={Monitor}
        eager
        alt=""
        logo={Logo}
        logoAlt="logo MÜPO"
        link="//mupo.cz/"
      >
        <p>
          Prodávat více střech, ukotvit náborovou komunikaci a&nbsp;obecně se
          lépe prezentovat navenek. Hlavní záměry a cíle stavební firmy MÜPO
          jsme přetavili do nového webu na míru&nbsp;–&nbsp;stavěli jsme na
          výzkumu mezi zákazníky, obchodními partnery i&nbsp;zaměstnanci
          a&nbsp;technicky na redakčním systému Drupal. Přečtěte si, jak celý
          proces probíhal. 👇
        </p>
      </ReferenceImageText>

      <ReferenceImageText>
        <H2>MÜPO</H2>
        <p>
          Společnost MÜPO,&nbsp;spol.&nbsp;s&nbsp;r.o. je renomovaná střechařská
          firma z&nbsp;Jižní Moravy. Její historie sahá až do roku 1992, kdy se
          začala specializovat na výstavbu a&nbsp;rekonstrukci všech typů
          střech, a&nbsp;to v plném rozsahu klempířských, pokrývačských
          a&nbsp;tesařských prací. V současnosti se zaměřuje také na zelené
          střechy, provětrávané fasády a&nbsp;dřevostavby.
        </p>
      </ReferenceImageText>

      <PaddingWrapper>
        <H2 className="mb-n4">Jak jsme postupovali</H2>
        <Container fluid className="text-center pt-5 mt-2">
          <Timeline data={MUPO_TIMELINE}></Timeline>
        </Container>
      </PaddingWrapper>

      <ReferenceImageText>
        <H2>Uživatelský výzkum</H2>
        <p>
          V uživatelském výzkumu jsme pracovali se základními cílovými skupinami
          –&nbsp;zákazníci /&nbsp;obchodní partneři a&nbsp;zaměstnanci.
        </p>
        <p>
          U zákazníků /&nbsp;obchodních partnerů bylo zapotřebí zjistit, jakým
          způsobem vybírají svého dodavatele; kdo o&nbsp;výběru rozhoduje; na
          základě jakých kritérií a&nbsp;zároveň, co může být při výběru
          dodavatele překážkou.
        </p>
        <p>
          V&nbsp;případě zaměstnanců jsme se zaměřili na zjednodušenou tvorbu
          hodnot v&nbsp;zaměstnání (<Link to="/hr-marketing">EVP</Link>). To,
          jak si vybírají svého zaměstnavatele; jak vypadá proces změny práce;
          čeho si na práci v&nbsp;MÜPO cení; co je pro práci řemeslníka
          specifické a&nbsp;s&nbsp;čím se setkali v&nbsp;předchozích zaměstnání.
        </p>
      </ReferenceImageText>

      <Infobox
        heading="EVP"
        text="EVP (Employer Value Proposition) je soubor benefitů
        a&nbsp;hodnot, které firma nabízí svým zaměstnancům. Jedná se
        o&nbsp;způsob, jak se odlišit od konkurence, přilákat kvalitní
        zaměstnance a&nbsp;udržet si ty stávající spokojené."
        img
      />

      <ReferenceImageText>
        <p>
          Abychom zjistili veškeré potřebné informace, použili jsme kombinaci
          následujících výzkumných metod: desk research, hloubkové rozhovory
          a&nbsp;card sorting. Na základě desk research jsme vytvořili prvotní
          hypotézy, které jsme ověřili hloubkovými rozhovory. Pomocí card
          sortingu jsme zjistili, jakým způsobem informace využít v&nbsp;rámci
          webových stránek a&nbsp;na co je kladen největší důraz.
        </p>
      </ReferenceImageText>

      <Infobox
        heading="Desk research"
        text="Desk research jako metoda výzkumu spočívá ve sběru
        a&nbsp;analýze dostupných písemných a&nbsp;elektronických
        zdrojů. Umožňuje získat relevantní data a&nbsp;poznatky bez
        přímého kontaktu s&nbsp;respondenty. Jedná se o&nbsp;efektivní,
        rychlou a&nbsp;cenově dostupnou metodu získávání informací pro
        analýzu, hodnocení a&nbsp;rozhodování."
      />
      <Infobox
        heading="Hloubkové rozhovory"
        text="Hloubkové rozhovory se řadí mezi kvalitativní metody
        výzkumu a&nbsp;slouží ke zkoumání názorů, postojů
        a&nbsp;zkušeností lidí. Pomocí otevřených otázek se výzkumník
        snaží podpořit diskuzi, zkoumá motivace, pocity
        a&nbsp;perspektivy respondentů. Díky tomu získá detailní vhled
        do problematiky, kterou se zabývá. Závěry nelze zobecnit na
        širší populaci, spíše umožňují získat podrobnější informace
        k&nbsp;dalšímu ověřování kvantitativními metodami. Hloubkové
        rozhovory přináší lepší pochopení nákupního chování
        a&nbsp;potřeb zákazníka."
      />

      <Infobox
        heading="Card sorting"
        text="Card sorting slouží k&nbsp;uspořádání informací do
          kategorií. Respondenti třídí karty podle svých vlastních
          kritérií, čímž umožňují identifikovat přirozené vzorce
          organizace a&nbsp;struktury. Používá se při návrhu informační
          architektury, uspořádávání obsahu na webových stránkách
          a&nbsp;vývoji taxonomií."
        img
      />

      <ReferenceImageText>
        <p>
          Díky uživatelskému výzkumu jsme získali cenné informace pro tvorbu
          wireframu webu a&nbsp;textového zadání pro grafika. Zároveň jsme
          zjistili, co může společnost MÜPO využít pro náborové kampaně.
        </p>
      </ReferenceImageText>
      <ContainerCustom>
        <img
          src={Figma}
          alt=""
          width="2000"
          height="1800"
          className="d-none d-md-block m-auto img-fluid"
          loading="lazy"
        />
        <img
          src={FigmaMobile}
          alt=""
          width="1000"
          height="1513"
          className="d-block d-md-none m-auto img-fluid"
          loading="lazy"
        />
        <p className="d-none d-md-flex justify-content-center">
          <em>Wireframe nového webu</em>
        </p>
        <p className="d-flex d-md-none justify-content-center">
          Ukázka části wireframu nového webu
        </p>
      </ContainerCustom>

      <PaddingWrapper>
        <PaddingWrapper>
          <Medailon
            personName="Jan Nobilis"
            position={`vedoucí vývoje<br/>a jednatel`}
            img={Sobol}
          >
            <p>
              Do procesu uživatelského výzkumu se vždy snažíme co nejvíc zapojit
              klienta. Zjištěné informace od jeho zákazníků a&nbsp;potenciálních
              zaměstnanců jsou jedny z&nbsp;nejcennějších, které může majitel
              firmy získat.
            </p>
          </Medailon>
        </PaddingWrapper>
      </PaddingWrapper>

      <NegativeMarginWrapper>
        <ContainerBackground style={ContainerBackgroundStyle.PrimaryBG}>
          <ContainerCustom>
            <H2>Tvorba webu</H2>
            <div className="w-md-50 mx-auto mb-5 pb-5">
              <p>
                Na základě wireframů jednotlivých stránek a&nbsp;textového
                zadání pro grafika jsme vytvořili vizuální návrh webu tak, aby
                korespondoval s&nbsp;brand manuálem firmy. Grafické návrhy jsme
                převedli do webových šablon a&nbsp;následně je nasadili do
                redakčního systému Drupal.
              </p>
              <p>
                Po předání grafického návrhu je proces tvorby webu obvykle
                podobný. Z&nbsp;grafického designu je potřeba vytvořit webové
                šablony, které jsou napojené na redakční systém. Poté je nutné
                vyřešit náročnější prvky na backendu (v&nbsp;tomto případě to
                byly různé filtrace realizovaných projektů) a&nbsp;nasadit
                analytické prvky. Následně všechno otestovat a&nbsp;seznámit
                klienta s&nbsp;redakčním systémem, aby byl schopen sám spravovat
                a&nbsp;aktualizovat obsah webových stránek.
              </p>
            </div>
          </ContainerCustom>
          <div className="d-md-flex justify-between">
            <div>
              <img
                src={Wireframe}
                alt=""
                width={1000}
                height={3000}
                loading="lazy"
                className="img-fluid mr-1 mb-2"
              />
            </div>
            <div>
              <img
                src={Wireframe2}
                alt=""
                width={1000}
                height={2750}
                loading="lazy"
                className="img-fluid ml-1"
              />
            </div>
          </div>
          <p className="d-flex justify-content-center">
            <em>Grafický návrh nového webu</em>
          </p>
        </ContainerBackground>
      </NegativeMarginWrapper>

      <PaddingWrapper biggerPaddingTop>
        <Medailon
          personName="Jan Nobilis"
          position={`vedoucí vývoje<br/>a jednatel`}
          img={Sobol}
        >
          <p>
            Rád vytvářím weby pro klienty napříč obory. Díky tomu se dozvím
            mnoho nových a&nbsp;zajímavých informací, které jsou mimo mou
            vlastní oblast odbornosti. Tím, že jsme provedli uživatelský výzkum
            pro vývoj nového webu pro řemeslníky a&nbsp;investory staveb, jsme
            byli schopni nahlédnout do jejich myšlení a&nbsp;rozšířit své obzory
            i&nbsp;v&nbsp;těchto oblastech.
          </p>
        </Medailon>
      </PaddingWrapper>

      <PaddingWrapper>
        <NegativeMarginWrapper>
          <H2>Co klient spoluprací získal</H2>
          <ContainerCustom>
            <Content noPadding>
              <ol>
                <li>Moderní web zaměřený na klíčové cílové skupiny.</li>
                <li>
                  Webovou prezentaci nasazenou na bezpečném a&nbsp;otevřeném
                  redakčním systému bez jakéhokoliv licenčního omezení (žádný
                  vendor lock-in). Systém průběžně spravujeme
                  a&nbsp;aktualizujeme.
                </li>
                <li>
                  Informace, které mu pomáhají v&nbsp;komunikaci se zákazníky
                  a&nbsp;zaměstnanci.
                </li>
              </ol>
            </Content>
          </ContainerCustom>
        </NegativeMarginWrapper>
      </PaddingWrapper>

      <PaddingWrapper>
        <Medailon personName="Martin Polášek" position="jednatel" img={Polasek}>
          <p>
            Na igloonet se bez váhání obrátím vždy, když potřebuji pomoct
            s&nbsp;kampaněmi, digitální identitou, webem, mailhostingem nebo
            reklamou. Někdy jsem přemýšlel, jestli to u&nbsp;konkurence
            nedokážeme vyřešit levněji. Zjistil jsem ale, že výsledky nikdy
            nebudou tak kvalitní, jaké jsem si představoval. Jedeme v tom spolu
            už více než 15&nbsp;let a&nbsp;nechystáme se to měnit.
          </p>
        </Medailon>
      </PaddingWrapper>
      <ContactForm contact="sobol" />
    </Layout>
  )
}

export default Mupo
